.switch {
    width: 60px;
    height: 30px;
    background-color: rgb(55, 55, 55);
    border-radius: 50px;
    display: flex;
    align-items: center;
}

.switch span {
    position: absolute;
    width: 24px;
    height: 24px;
    background-color: white;
    border-radius: 25px;
    margin-left: 3px;
    transition: 0.3s;
}

.switch.active span {
    transform: translateX(30px);
}