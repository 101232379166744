.portfolio {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5rem;
}

.portfolio article {
    max-width: 1000px !important;
}

.project-square1 {
    width: 300px;
    height: 300px;
    position: absolute;
    left: -150px;
    bottom: -150px;
    z-index: 0;
    transform: rotate(45deg);
    opacity: 0.5;
}

.project-square2-container span {
    width: 80px;
    height: 250px;
    position: absolute;
    right: 0;
    bottom: 500px;
    z-index: 0;
}

.project-square2-container span:last-of-type {
    transform: rotateX(180deg);
    bottom: 250px;
}

.projects {
    width: 90%;
    max-width: 1300px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 3rem;
    z-index: 1;
}

.project {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    font-size: 1.5rem;
    padding: 2rem 1rem;
    border-radius: 7px;;
}

.project h2 {
    font-size: 2.5rem;
    text-align: center;
}

.project img {
    width: 300px;
}

.project p {
    width: 90%;
}

.pill-list {
    width: 70%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    & li {
        width: fit-content;
        text-align: center;
        border-radius: 1rem;
        padding: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    & p {
        width: fit-content;
        padding: 0 0.5rem;
    }
}

.project-buttons {
    display: flex;
    width: 90%;
    justify-content: space-between;
}

.project-buttons button {
    font-size: 1.5rem;
    border-radius: 5px;
    background-color: #40c463;
}

.project-buttons button:hover {
    cursor: pointer;
    background-color: hsla(136, 53%, 51%, 0.44);
}

.project-buttons button a {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

@media only screen and (min-width: 1000px) {
    .project-square2-container span {
        height: 400px;
        width: 150px;
    }
    .project-square2-container span:last-of-type  {
        bottom: 100px
    }
}