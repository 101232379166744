.App {
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  font-size: 2rem;
  /* transition: 0.5s; */
  font-family: 'Open Sans';
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;
  padding-bottom: 3rem;
}

@media only screen and (min-width: 1000px) {
  .App {
    gap: 10rem;
  }
}