.header-div {
    position: fixed;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1.5rem;
    backdrop-filter: blur(10px);
    z-index: 100;
    overflow: visible;
}

.nav-menu {
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    z-index: 500;
}

.nav-menu.active span:first-of-type{
    transform: 
    rotate(45deg) 
    translateY(7px) 
    translateX(7px);
}

.nav-menu.active span:nth-of-type(2) {
    width: 0;
}

.nav-menu.active span:last-of-type {
    transform: 
    rotate(-45deg)
    translateY(-7px)
    translateX(7px);
}

.nav-menu span {
    width: 100%;
    height: 3px;
    background-color: grey;
    transition: 0.5s;
    border-radius: 3px;
}

header {
    width: 50%;
    height: 100vh;
    transform: translateX(-100vw);
    position: absolute;
    top: 0;
    left: 0;
    transition: 1s;
    padding-top: 50px;
    z-index: 100;
}

header.active {
    transform: translateX(0vw);
}

header ul {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
}

header button {
    font-size: 1.5rem;
    border-bottom: 1px solid #40c463;
}

header button:hover {
    cursor: pointer;
    background-color: hsla(136, 53%, 51%, 0.44);
}

@media only screen and (min-width: 1000px) {
    .header-div {
        box-shadow: none;
        align-items: center;
    }
    .nav-menu {
        visibility: hidden;
    }
    header {
        position: static;
        width: 90%;
        transform: translateX(0);
        height: fit-content;
        padding-top: 0;
        box-shadow: transparent;
    }
    header ul {
        flex-direction: row;
        justify-content: flex-end;
    } 
    header ul li:first-of-type {
        position: absolute;
        left: 0;
        display: flex;
    }
}