body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  font-size: 10px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: transparent;
  overflow-x: hidden;
}

#root {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: fit-content; 
  background-color: white;
  overflow: hidden;
  position: relative;
}

h1, h3 {
  font-family: 'Times New Roman', Times, serif;
}

li {
  list-style: none;
}

button {
  width: 130px;
  height: 50px;
  border: none;
}

a {
  text-decoration: none;
}

@media only screen and (min-width: 1000px) {
  button {
    width: 150px;
  }
}