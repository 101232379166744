footer {
    width: 90%;
    display: flex;
    justify-content: space-between;
    max-width: 500px;
    z-index: 1;
}

footer button {
    font-size: 2rem;
}

footer svg:hover {
    cursor: pointer;
    color: #40c463;
}
